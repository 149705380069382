import _ from 'lodash';
import React from 'react';
import ReactDOM from 'react-dom';
import airbrake from 'common/airbrake';

import { dateLocalize } from 'common/locale';
import InternalAssetManager from './components/internal_asset_manager';

airbrake.init(_.get(window, 'serverConfig.airbrakeProjectId'), _.get(window, 'serverConfig.airbrakeKey'));

ReactDOM.render(<InternalAssetManager />, document.querySelector('#internal-asset-manager-asset-browser'));

Array.from(document.querySelectorAll('.dateLocalize')).forEach(dateLocalize);
