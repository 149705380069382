import React, { Component } from 'react';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

import AssetBrowser from 'common/components/AssetBrowser';
import * as constants from 'common/components/AssetBrowser/lib/constants';
import * as ceteraHelpers from 'common/components/AssetBrowser/lib/helpers/cetera';
import { hasAnyIncomingFederation } from 'common/federation/utils';
import { fetchSettings, provenanceRequiresManualApproval } from 'common/core/approvals';
import { FeatureFlags } from 'common/feature_flags';

// This page is often referred to as SIAM (Socrata Internal Asset Manager) and the component it uses is
// called the AssetBrowser which is used on the user profile page, the approvals page and asset selectors.

const assetsRequireManualApproval = (settingsResponse) => {
  if (!settingsResponse) {
    return false; // TODO loading feedback? We never had it.
  }

  const scopes = [constants.AUTHORITY_OFFICIAL, constants.AUTHORITY_COMMUNITY];
  return scopes.some(scope =>
    provenanceRequiresManualApproval(settingsResponse, scope)
  );
};

export class InternalAssetManager extends Component {
  componentDidMount() {
    const { approvalsSettings } = this.props;
    if (!approvalsSettings) {
      fetchSettings().then((response) => {
        this.setState({ approvalsSettings: response });
      });
    }
  }

  render() {
    /**
     * Columns used everywhere other than the Federated tab.
     */
    const columns = [
      constants.COLUMN_TYPE,
      constants.COLUMN_NAME,
      constants.COLUMN_ACTIONS,
      constants.COLUMN_LAST_UPDATED_DATE,
      constants.COLUMN_CATEGORY,
      constants.COLUMN_OWNER,
      constants.COLUMN_AUDIENCE
    ];

    /**
     * Columns used in the Federated tab.
     */
    const federationColumns = [
      constants.COLUMN_TYPE,
      constants.COLUMN_NAME,
      constants.COLUMN_SOURCE,
      constants.COLUMN_ACTIONS,
      constants.COLUMN_LAST_UPDATED_DATE,
      constants.COLUMN_CATEGORY,
      constants.COLUMN_OWNER,
      constants.COLUMN_AUDIENCE
    ];

    const forUser = ceteraHelpers.getCurrentUserId();
    const sharedTo = ceteraHelpers.getCurrentUserId();
    const teamIds = ceteraHelpers.getCurrentUsersTeams();

    const tabs = {
      [constants.TAB_MY_ASSETS]: {
        props: {
          baseFilters: {
            forUser
          },
          columns
        }
      },
      [constants.TAB_SHARED_TO_ME]: {
        props: {
          baseFilters: {
            sharedTo
          },
          columns
        }
      }
    };

    if (FeatureFlags.value('enable_teams') && !isEmpty(teamIds)) {
      tabs[constants.TAB_MY_TEAM_ASSETS] = { props: { columns } };
    }

    const userCanSeeAllAssets = ceteraHelpers.isSiteMemberOrSuperAdmin();

    if (userCanSeeAllAssets) {
      tabs[constants.TAB_ALL_ASSETS] = { props: { columns } };
    }

    let federatedTabVisible = false;
    if (hasAnyIncomingFederation()) {
      // Everyone can see catalog federation info, so everyone gets the tab.
      // or user can see internal to public data federations
      federatedTabVisible = true;
    } // else: no federations active, so no tab.

    if (federatedTabVisible) {
      tabs[constants.TAB_FEDERATED] = {
        props: {
          columns: federationColumns
          // You'll notice that we're not adding any baseFilters for the federations here.
          // This is because baseFilter is designed only for hidden, non-interactive filters
          // that have no interaction with user-defined filters. This is not the case for
          // federations - we allow the user to refine the source domain to a specific site
          // even on the federation tab. The logic that implements the federation filtering
          // for this tab actually lives in
          // common/components/AssetBrowser/lib/helpers/cetera.js
        }
      };
    }

    return (
      <AssetBrowser
        // This has the effect of filtering to all scopes of assets from the current domain, and
        // only public assets from federated domains. See EN-39820 for desired future state.
        assetSelector={true}
        initialTab={userCanSeeAllAssets ? constants.TAB_ALL_ASSETS : constants.TAB_MY_ASSETS}
        showAssetCounts
        showFilters
        showSearchField
        showAwaitingApprovalFilter={assetsRequireManualApproval(
          this.props.approvalsSettings ||
          get(this.state, 'approvalsSettings') // Auto fetched if not available in props.
        )}
        tabs={tabs} />
    );
  }
}

export default InternalAssetManager;
